@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin12 {
  height: 100%;
  background-color: #f5f6f9;
  line-height: normal;

  .header {
    padding: 20px 16px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 220px;
    background-image:linear-gradient(1turn, #DDDDFA, #7D7DFD);

    .logoBox {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      .logo {
        margin-right: 10px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/purple-theme/logo.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;

        .logoText {
          width: 80px;
          height: 21px;
        }

        .youqianLogoText {
         // width: 54px;
          height: 21px;
        }

        .youqianLogoText {
          //width: 60px;
          height: 21px;
        }

        .logoText2 {
          margin-top: 4px;
          width: 76px;
          height: 10px;
        }

        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          font-weight: 600;
          color: #ffffff;
          transform: scale(0.86) translateX(-10px);
        }
      }
    }

    .inputWrapper {
      margin-top: 25px;
      border-radius: 8px;
      width: 343px;
      background: linear-gradient(180deg, #fbe5d7 0%, #ffffff 27.54%);

      .inputWrapperHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 29px;
        width: 100%;
        height: 50px;
      }

      .inputWrapperContainer {
        margin-top: 12px;
        padding: 24px 12px 16px;

        .inputWrapperContainetTips {
          margin-bottom: 8px;
          font-size: 14px;
          color: #262626;
        }

        .inputBox {
          position: relative;
          display: flex;
          align-items: center;

          .inputBoxSymbol {
            font-size: 38px;
            line-height: 52px;
          }

          :global {
            .adm-input-element {
              font-size: 33px;
              font-weight: 600;
              line-height: 30px;

              &::placeholder {
                font-size: 22px;
                line-height: 30px;
                font-weight: normal;
              }
            }
          }

          .text {
            position: absolute;
            right: 0;
            bottom: 14px;
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #de9960;

            .clear {
              padding-left: 9px;
              font-size: 16px;
              color: #8c8c8c;
            }
          }
        }

        .moneyOptions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .moneyItem {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            padding: 4px 8px;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            color: #8c8c8c;
            background-color: #f3f4f6;
            flex: 1;
            box-sizing: border-box;
            line-height: normal;

            &:nth-child(2n + 2) {
              margin: 0 8px;
            }

            &.moneyItemActive {
              border: 1px solid $text-skin12;
              color: $text-skin12;
              background-color: #fff;
            }
          }
        }

        .line {
          margin: 16px 0;
          width: 100%;
          height: 1px;
          background-color: #eaeaea;
        }

        .moneyRateTips {
          display: flex;
          align-items: center;

          .moneyRateText1 {
            margin-right: 8px;
            border-radius: 4px;
            padding: 3px 8px;
            font-size: 12px;
            color: #ffffff;
            background-color: #de9960;
          }

          .moneyRateText2 {
            color: #262626;
          }
        }
      }
    }
  }

  .checkBox {
    margin: 11px auto 16px;
    border-radius: 8px;
    padding-bottom: 32px;
    width: 352px;
    background: #ffffff;

    .title {
      border-radius: 6px 6px 0 0;
      width: 100%;
      height: 38px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: #eab765;
      background: $linear-gradient-skin12;
      line-height: 38px;
    }

    h3 {
      padding: 12px 16px 0;
      font-size: 16px;
      font-weight: 600;
      color: #333333;

      span {
        font-size: 12px;
        font-weight: normal;
        color: #bb6c1d;
      }
    }

    .loanTermList {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      padding: 0 16px;
      flex-wrap: wrap;

      .loanTermItem {
        border-radius: 5px;
        width: 101px;
        height: 30px;
        font-weight: 600;
        text-align: center;
        background-color: #f3f4f6;
        box-sizing: border-box;

        p {
          font-size: 14px;
          color: #8c8c8c;

          &.samllText {
            font-size: 12px;
          }
        }

        &.active {
          background-color: $text-skin12;

          p {
            font-size: 14px;
            color: #ffffff;
          }
        }

        &.is360Channel {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
        }
      }
    }

    .bottomBody {
      margin-top: 20px;
      box-sizing: border-box;
      padding: 0 16px;

      p {
        display: flex;
        justify-content: center;
        width: 100%;

        span {
          display: inline-block;
          padding: 0 16px;
          height: 41px;
          color: #c16818;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          line-height: 41px;
          background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
        }
      }

      .phoneBox {
        display: flex;
        align-items: center;
        margin: 9px auto 0;
        border-radius: 8px;
        padding-left: 16px;
        height: 46px;
        background: #f4f5f7;

        .label {
          width: 170px;
          font-size: 16px;
          text-align: center;
        }

        :global {
          .adm-input-element {
            font-size: 16px;
            line-height: 30px;

            &::placeholder {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }

      .getCms {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12px;
        border-radius: 6px;
        height: 46px;
        font-size: 16px;
        color: #ffffff;
        background-image: $linear-gradient-skin12;
      }

      .agreement {
        @include flex-cc;

        flex-wrap: wrap;

        .primary {
          color: #de9960;
        }

        margin-top: 20px;
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;

        &.animation {
          position: relative;
          animation: shake 0.1s;
        }

        @keyframes shake {
          0% {
            left: 8px;
          }

          50% {
            left: -8px;
          }

          100% {
            left: 0;
          }
        }

        .checked {
          margin-right: 6px;
          border: 1px solid #dddddd;
          width: 15px;
          height: 15px;
          border-radius: 35%;

          &.active {
            position: relative;
            border: none;
            background: #ffd28a;

            &::before {
              position: absolute;
              top: 4px;
              left: 2.2px;
              width: 10px;
              height: 7px;
              content: "";
              background-image: url("~@imgs/activity/check.png");
              background-size: 100%;
            }
          }
        }
      }
    }
  }

  .tipBox {
    padding-bottom: 50px;

    .tip {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100vh;
    background-color: rgb(0 0 0 / 70%);

    &.showPopup {
      display: block;
    }

    .dialogMain {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 8px;
      padding: 20px 0;
      width: 320px;
      height: 180px;
      background-color: #ffffff;
      transform: translate(-50%, -50%);
      box-sizing: border-box;

      .close {
        margin-right: 20px;
        padding-right: 20px;
        width: 100%;
        font-size: 20px;
        text-align: right;
        color: rgb(214 217 223);
        box-sizing: border-box;
      }

      .inputBox {
        display: flex;
        align-items: center;
        margin: 10px auto;
        border: 1px solid #eab765;
        border-radius: 8px;
        padding-left: 20px;
        width: 285px;
        height: 48px;

        .code {
          width: 150px;
          text-align: center;
          color: #eab765;
        }

        :global {
          .adm-input-element {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }

      .getQuota {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 18px auto;
        border-radius: 6px;
        width: 283px;
        height: 44px;
        font-size: 16px;
        color: #ffffff;
        background-image: $linear-gradient-skin12;
      }
    }
  }
}

:global {
  .adm-auto-center {
    .adm-auto-center-content {
      text-align: center !important;
    }
  }
}
